import { useState, useEffect  } from 'react';
import { useSession, useUser } from "@clerk/nextjs";

const Crisp = () => {
    const { session } = useSession();
    // const { isLoaded, userId, sessionId, getToken } = useAuth();
    const { user } = useUser()
    const [sessionReset, setSessionReset] = useState()
    const [emailSet, setEmailSet] = useState()

    async function setTokenId() {
      const res = await fetch('/api/user/getCrispToken')
      const result = await res.json()

      if (window.CRISP_TOKEN_ID != result.token) {    
        window.CRISP_TOKEN_ID = result.token
        
        // console.log(`${session.user.primaryEmailAddress.emailAddress}`);
        // window.$crisp.push(["set", "user:email", [`${session.user.primaryEmailAddress.emailAddress}`]]);
        // window.$crisp.push(["set", "user:nickname", [`${session.user.primaryEmailAddress.emailAddress.split('@')[0]}`]])
        setSessionReset(true)
        setEmailSet(false)
      }
    }
  
    useEffect(()=> {
      if (session && window.CRISP_TOKEN_ID == undefined) {
        setTokenId()
      }
      if (session && emailSet == false) {
        // console.log("EMAIL:");
        // console.log(`${session.user.primaryEmailAddress.emailAddress}`);
        window.$crisp.push(["set", "user:email", [`${session.user.primaryEmailAddress.emailAddress}`]]);
        window.$crisp.push(["set", "user:nickname", [`${session.user.primaryEmailAddress.emailAddress.split('@')[0]}`]])
        setEmailSet(true)
      }
      if (sessionReset) {
        // console.log("sessionreset triggered");
        window.$crisp.push(["do", "session:reset"]);
        setSessionReset(false)
        
      }
    })

      return null;
  }
  export default Crisp
  