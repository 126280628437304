// import '../styles/globals.css'
// import 'tailwindcss/tailwind.css'
import NProgress from 'nprogress'
import Router from 'next/router'
import Head from 'next/head'
import { ChakraProvider, ColorModeProvider, useColorMode } from "@chakra-ui/react"
import customTheme from '../styles/theme'
import { Global, css } from '@emotion/react'
import { useEffect, useState} from 'react'
import Script from 'next/script'
import { useRouter } from 'next/router'
import * as gtag from '../lib/gtag'
import { ClerkProvider, SignedIn, ClerkLoading } from '@clerk/nextjs';
import '@fontsource/poppins/300.css'
import '@fontsource/poppins/400.css'
import '@fontsource/poppins/500.css'
import '@fontsource/poppins/600.css'
import '@fontsource/poppins/700.css'
import '@fontsource/poppins/800.css'
import '@fontsource/poppins/900.css'
import '@fontsource/inter/100.css'
import '@fontsource/inter/200.css'
import '@fontsource/inter/300.css'
import '@fontsource/inter/400.css'
import '@fontsource/inter/500.css'
import '@fontsource/inter/600.css'
import '@fontsource/inter/700.css'
import '@fontsource/inter/800.css'
import '@fontsource/inter/900.css'
import dynamic from 'next/dynamic'
import Crisp from '../components/crisp'

NProgress.configure({ showSpinner: false });
Router.events.on('routeChangeStart', (url) => {
  // console.log(`Loading: ${url}`)
  NProgress.start()
})
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

// const CrispWithNoSSR = dynamic(
//   () => import('../components/crisp'),
//   { ssr: false }
// )

const GlobalStyle = ({ children }) => {
  const { colorMode } = useColorMode()

  return (
    <>
      <Global
        styles={css`
          ::selection {
            background-color: #90CDF4;
            color: #fefefe;
          }
          ::-moz-selection {
            background: #ffb7b7;
            color: #fefefe;
          }
          html {
            min-width: 356px;
            scroll-behavior: smooth;
          }
          #__next {
            display: flex;
            flex-direction: column;
            min-height: 100vh;
            background: ${colorMode === 'light' ? 'white' : '#15181C'};
          }
        `}
      />
      {children}
    </>
  )
}

function MyApp({ Component, pageProps: { session, ...pageProps }, err }) {
  const router = useRouter()

  return (
      <>
      <Script
      id="crisp-widget"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `
          window.$crisp=[];
          window.CRISP_WEBSITE_ID="${process.env.NEXT_PUBLIC_CRISP_WEBSITE_ID}";
          (function(){

            const d = document;
            const s = d.createElement("script");
            s.src = "https://client.crisp.chat/l.js";
            s.async = 1;
            d.getElementsByTagName("head")[0].appendChild(s);
          })();`,
      }}
    />
       <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
      />
      <Script
        id="gtag-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${gtag.GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `,
        }}
      />

    {/* <SessionProvider session={session}> */}
    <ClerkProvider {...pageProps} >
        <ChakraProvider resetCSS theme={customTheme}>
          <ColorModeProvider
            options={{
              initialColorMode: "light",
              useSystemColorMode: true,
              }}
            >
            <Head>
            </Head>
            <GlobalStyle>
            {/* <CrispWithNoSSR /> */}
            <Crisp />
              <Component {...pageProps} err={err}/>
            </GlobalStyle>
          </ColorModeProvider>
        </ChakraProvider>
        </ClerkProvider>
        {/* </SessionProvider> */}
        
      </>
  )
}

export default MyApp
