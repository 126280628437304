import { extendTheme, useStyleConfig } from "@chakra-ui/react"
import { theme as chakraTheme } from "@chakra-ui/react"
import { createBreakpoints, mode } from "@chakra-ui/theme-tools"

const fonts = {
    ...chakraTheme.fonts,
    heading: 'Inter, sans-serif',
    body: 'Inter, sans-serif',
}

const Menu = {
  parts: ['menu', 'list','item'],
  baseStyle: {
    menu: {
    //   boxShadow: 'lg',
    //   flexDirection: 'column',
    //   py: '2',
    },
    list: {
      // background: props.colorMode === 'dark' ? 'red.300' : 'red.500',
      // bg: mode('white', 'blue.800')(props),
      // borderColor: "green.500"
    },
    item: {
      // fontWeight: 'medium',
      // lineHeight: 'normal',
      // fontSize: '14',
      // color: 'gray.200',
      // // background: 'red',
      // _hover: { 
      //   // background: 'blue' 
      // }
    },
  }
}

const breakpoints = createBreakpoints({
    sm: "40em",
    md: "52em",
    lg: "64em",
})

const overrides = {
    ...chakraTheme,
    fonts,
    breakpoints,
    fontWeights: {
        thin: 100,
        extralight: 200,
        light: 300,
        regular: 400,
        medium: 500,
        semibold: 600,
        bold: 700,
        extraBold: 800,
        black: 900
    },
    fontSizes: {
        xs: "12px",
        sm: "14px",
        md: "16px",
        lg: "18px",
        xl: "20px",
        "2xl": "24px",
        "3xl": "28px",
        "4xl": "36px",
        "5xl": "48px",
        "6xl": "64px",
    },
    colors: {
      error: {
        500: "#F31147"
      },
      // success: {

      // }
      brand: {
        100: "#f7fafc",
        // ...
        900: "red",
      },
        neutral: {
          1000: "#24292E",
          900: "#383F45",
        },
        darkGray: {
          900: '#15181C',
          800: '#1B1E1F',
          500: '#727A81'
        }
      },

      baseStyle: {
        fontWeight: "normal",
      },
      components: {
        Menu
      }
}

const customTheme = extendTheme(overrides)

// function CustomMenu2(props) {
//   const { size, variant, ...rest } = props
//   const styles = useStyleConfig('CustomMenu', { size, variant })
//   return <Menu as='span' sx={styles} {...rest} />
// }

export default customTheme